@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: rgb(242, 242, 242);
}

body * {
 @apply transition-colors duration-200;
}